// Try to keep the keys in this translation file in ascending alphabetical order in order to quickly find translations
export default {
	alert: {
		changed_order_out_of_sync:
			'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
		check_email: 'Controlleer je e-mailadres',
		complete_order_in_time: 'Zorg ervoor dat je jouw bestelling voltooit voordat de timer afloopt.',
		complete_payment_in_time: 'Zorg ervoor dat je jouw betaling voltooit voordat de timer afloopt.',
		logged_in_as: 'Je bent ingelogd als {name}, wil je met een ander account bestellen? Log dan uit.',
		login_required: {
			title: 'Login verplicht',
			lead: "Je moet eerst inloggen om deze shop te kunnen betreden. Klik op de knop 'inloggen' of klik op het inlog icoontje rechtsboven.",
			cta: 'Inloggen'
		},
		no_stock: 'Dit artikel is niet op voorraad. Je kunt een alternatief selecteren.',
		no_refund_choice: 'Je hebt nog geen keuze gemaakt met betrekking tot boekingsbescherming.',
		no_checkout: 'Je kunt nog niet afrekenen omdat:',
		no_addons: "Helaas hebben we momenteel geen extra's voor dit pakket.",
		sold_out: 'Dit item is uitverkocht. Je kunt een alternatief selecteren.',
		sold_out_combination: 'Deze pakketcombinatie is uitverkocht.'
	},
	checkout: {
		confetti: 'Meer confetti!',
		cta: 'Ga naar betalen',
		entertainment_tax: {
			lead: 'Vermaakbelasting, ook wel entertainmentbelasting genoemd, is een belasting die wordt geheven op verschillende vormen van vermaak of amusement, zoals bioscoopkaartjes, concerten, pretparken, sportevenementen of andere publieke vormen van entertainment.  De opbrengst gaat naar de overheid.',
			title: 'Borg'
		},
		included_vat: 'Incl. BTW & servicecosts',
		lead: 'Controleer de inhoud van jouw winkelwagen voordat je jouw bestelling voltooit.',
		lead_complete: 'Vul jouw persoonlijke gegevens in.',
		lead_upgrade: 'Hieronder vindt je een overzicht van jouw bestelling.',
		payment_method: {
			discount_next_step: 'Jouw korting wordt in de volgende stap afgetrokken van het totale bedrag',
			lead_zero:
				"Er zijn geen kosten voor verbonden aan deze bestelling, omdat het totale bedrag nul euro is. Klik op de onderstaande knop 'Voltooi bestelling' om de bestelling te bevestigen en af te ronden.",
			loading: 'Een moment, we zijn even de beschikbare betaalmethoden aan het controleren ...',
			method: 'Betaalmethode',
			redirect_reload:
				'We sturen je door naar je betaalprovider. Wil je toch een andere betaalmethode selecteren en/of wordt je na 1 minuut niet doorverwezen?',
			select: 'Selecteer een betaalmethode',
			zero: 'Selecteer "Geen betaling vereist" om jouw bestelling te voltooien.'
		},
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			add: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			default: 'Voeg boekingsbescherming toe voor slechts',
			extend: 'Verlengt de restitutiebescherming voor jouw upgrade voor',
			upgrade: 'Voeg boekingsbescherming toe voor je upgrade voor slechts',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		order_fee: 'Bestelkosten',
		price: 'Prijs',
		service_fee: 'Ticket Service Fee',
		shipping_costs: {
			explainer: 'Het verzenden van (sommige) artikelen brengt verzendenkosten met zich mee.',
			label: 'Bereken verzendkosten'
		},
		subtotal: 'Subtotaal',
		tax: 'BTW',
		terms: "Door op de knop 'Ik ben klaar om te bestellen' te klikken maak ik een order aan en ga ik akkoord met de {termsConditions} en bevestig ik dat ik de {privacyPolicy} heb gelezen. Door te accepteren, ga ik ook akkoord met de algemene voorwaarden van onze partner {paymentProvider}. Wanneer ik bij mijn bestelling ook de merchandise-artikelen heb geselecteerd, verklaar ik ook akkoord te gaan met de {merchandise}",
		title: 'Je winkelwagen',
		total: 'Totaal',
		tourist_tax: {
			lead: 'Toeristenbelasting is een bedrag dat door de gemeente wordt gevraagd. In dit geval is dat € 5,- per campingbezoeker',
			title: 'Toeristenbelasting'
		},
		upsell: {
			cta: 'Meer Producten',
			lead: 'Voeg deze producten toe aan je winkelmandje',
			title: 'Anderen kochten ook:'
		},
		voucher: {
			add: 'Voucher -code toevoegen',
			invalid: 'Deze voucher -code is ongeldig',
			used: 'Deze voucher -code is al gebruikt',
			redeem: 'Voucher inwisselen',
			success: 'Voucher heeft succesvol ingewisseld!'
		}
	},
	error: {
		accommodation_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd. Deze accommodatie is uitverkocht. Mensen voltooien nu hun boeking. Als de betalingen niet worden bevestigd, zal dit pakket weer beschikbaar zijn. ',
		accommodation_ticket_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd. Mensen voltooien nu hun boeking. De tickets zijn momenteel niet beschikbaar. Als betalingen niet worden bevestigd, is dit ticket weer beschikbaar. ',
		accommodation_not_available:
			'Deze accommodatie is niet beschikbaar voor dit aantal mensen of hij is uitverkocht. Mensen voltooien nu hun boeking. Als de betalingen niet worden bevestigd, zal dit pakket weer beschikbaar zijn.',
		adyen_reload: 'Er is een fout opgetreden bij het laden van de betaalmethodes. Om deze opnieuw te laden, ',
		voucher_invald: 'Deze voucher-code is ongeldig',
		voucher_user: 'Deze voucher-code is al gebruikt',
		voucher_redeem_error: 'Er is een fout opgetreden bij het verzilveren van de voucher. Probeer het opnieuw. ',
		form_covid_not_accepted: 'Je bent het nog niet eens met de terugbetalingsvoorwaarden van COVID.',
		form_has_errors: 'Corrigeer de fouten in het formulier om jouw bestelling te voltooien.',
		form_incomplete: 'Vul alle vereiste velden in *',
		form_no_country_selected: 'Je hebt nog geen land geselecteerd om de verzendkosten te berekenen.',
		form_no_insurance_choice: 'Geef aan of je een boekingsbescherming voor jouw bestelling wilt toevoegen.',
		form_no_shipping_costs_selected: 'Je hebt nog geen verzendoptie geselecteerd om de verzendkosten te berekenen.',
		form_terms_not_accepted: 'Accepteer de algemene voorwaarden om jouw bestelling te voltooien.',
		generic:
			'Probeer het opnieuw. Vernieuw deze pagina en voeg, indien nodig, lid van de wachtrij om opnieuw toegang te krijgen tot de ticketwinkel. ',
		invalid_request: 'Er is een ongeldig verzoek gedaan.Probeer het opnieuw. ',
		no_config_found: 'Een OSS -configuratie ontbreekt.Neem contact op met de ondersteuning. ',
		order_lookup_failed:
			'Er is een fout opgetreden bij het opzoeken van jouw bestelling. Er ging iets mis aan onze zijde. Probeer het later opnieuw',
		order_lookup_invalid:
			'Jouw bestelnummer lijkt ongeldig, kopieer het juiste nummer uit de e-mail die je hebt ontvangen.',
		order_lookup_no_order:
			'We kunnen geen bestelling vinden met dat bestelnummer. Kopieer het juiste bestelnummer uit van de e-mail die je hebt ontvangen.',
		page_not_found: 'Pagina niet gevonden.',
		product_sold_out: 'Dit product is uitverkocht.',
		shipping_unavailable: 'Verzending is momenteel niet beschikbaar voor jouw locatie.',
		something_went_wrong_contact_us:
			'Er ging iets mis. Probeer het a.u.b. opnieuw. Als deze fout alsnog optreedt, neem dan contact met ons op.',
		undefined: 'Er is een onbekende fout opgetreden.',
		code: {
			0: '--modal-closed--',
			1000: {
				title: 'Oeps...',
				message: 'Er ging iets mis.Probeer het opnieuw. '
			},
			2000: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			2001: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			2002: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			3000: {
				title: 'Registratiefout',
				message:
					'Er is geen registratie gekoppeld aan dit {Brand} -account. Neem contact op met info@q-dance.com voor meer informatie.'
			},
			3001: {
				title: 'Registratiefout',
				message: 'Er is al een bestelling bij dit account geplaatst'
			},
			3002: {
				title: 'Registratiefout',
				message: 'Registratie is gesloten.'
			},
			3003: {
				title: 'Niet toegestaan',
				message: 'De winkel is nog niet open voor u.Controleer jouw tijdslot en probeer het opnieuw. '
			},
			3005: {
				title: 'Oeps ... je bent al opgedragen',
				message: 'Je kunt maar één lidmaatschap per account kopen.'
			},
			4000: {
				title: 'De winkel is gesloten'
			},
			4001: {
				title: 'Bedankt',
				message: 'Jouw verlanglijst is met succes opgeslagen!'
			},
			4002: {
				title: 'Upgrade -fout',
				message:
					'Bestelling al verwerkt of niet gevonden. Als je denkt dat dit een fout is, klikt je opnieuw op de e -maillink. '
			},
			4003: {
				title: 'Upgrade -fout',
				message:
					'Bestelling al verwerkt of niet gevonden. Als je denkt dat dit een fout is, klikt je opnieuw op de e -maillink. '
			},
			4004: {
				title: "Time's up",
				message:
					'Vanwege zwaar verkeer kunt je slechts een beperkte tijd doorbladeren. Je kunt de winkel opnieuw binnenkomen door op de link in jouw e-mail te klikken. ',
				label_cancel: false
			},
			4005: {
				title: 'Geen geldige bestelling ID',
				message:
					'Bestelling al verwerkt of niet gevonden. Als je denkt dat dit een fout is, klikt je opnieuw op de e -maillink. ',
				label_cancel: false
			},
			4006: {
				title: 'Geen geldige toegangs -ID',
				message:
					'Je hebt een geldige toegangs -ID nodig om de winkel in te gaan.Probeer opnieuw op de link in de e -mail te klikken. ',
				label_cancel: false
			},
			4364: {
				title: 'Je toegangslink is tijdelijk vergrendeld',
				message:
					'Probeer het opnieuw over 10 minuten. Open de shop op slechts één tabblad en refresh de pagina niet.',
				label_cancel: false
			},
			5000: {
				title: 'Product uitverkocht'
			},
			5001: {
				title: 'Product niet gevonden'
			},
			5002: {
				title: 'Geen reispakket (s) gevonden',
				message: 'Maak een andere selectie'
			},
			5003: {
				title: 'Verzending niet mogelijk',
				message:
					'Helaas is het niet mogelijk om jouw producten naar het land te verzenden dat je hebt geselecteerd. Kies een ander land of verwijder de producten uit je winkelmand.'
			},
			6000: {
				title: 'Verander registratie',
				message:
					'Please note that you need to reselect your type of registration (Travel & Stay Packages or ticket only) to enter the member sale. Please complete your registration again at the next step.',
				label_cancel: 'Annuleren'
			},
			6001: {
				title: 'Item verwijderen',
				message: 'Weet je zeker dat je dit item wilt verwijderen?',
				label_cancel: 'Annuleren'
			},
			6002: {
				title: "Time's up",
				message:
					'Het lijkt erop dat het te lang duurde voor je je bestelling kon voltooien. Maak je geen zorgen, je kunt het opnieuw proberen door op de onderstaande knop te klikken. Zorg ervoor dat je je bestelling binnen 7 minuten voltooit!',
				label_cancel: false,
				label_confirm: 'Probeer het opnieuw'
			},
			6003: {
				title: 'Geen ticket (s) geselecteerd',
				message:
					'Jouw bestelling bevat een accommodatie zonder toegangskaarten.Houd er rekening mee dat de ingang van {festivalid} alleen mogelijk is met een toegangsticket. Weet je zeker dat je wilt doorgaan met jouw aankoop? ',
				label_cancel: false,
				label_confirm: 'Doorgaan'
			},
			6004: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: false,
				label_confirm: 'login'
			},
			6005: {
				title: 'Dediqated Lid Sale',
				message: '<p> Je hebt een toegewijde lidmaatschap nodig om deze winkel te betreden. </p>',
				label_cancel: false,
				label_confirm: 'Winkel'
			},
			6010: {
				title: 'Weet je het zeker?',
				message: '<p> weet je zeker dat je je bestelling wilt annuleren? </p>',
				label_cancel: 'Nee',
				label_confirm: 'Ja'
			},
			7000: {
				title: 'Sorry!',
				message:
					'De Travel & Stay Lid -verkoop begint op 29 februari. Als je er toegang aan wilt krijgen, moet je jouw registratie wijzigen om te reizen en te blijven.Alle resterende pakketten zullen ook te koop zijn tijdens de ticketlidverkoop op 7 maart. '
			},
			8000: {
				title: 'Sorry!',
				message: ''
			},
			8001: {
				title: 'Sorry!',
				message: '',
				label_cancel: false
			},
			8002: {
				title: 'Er is een fout opgetreden',
				message: '',
				label_cancel: false
			},
			8003: {
				title: '',
				message: '',
				label_cancel: 'DOORGAAN'
			}
		}
	},
	form: {
		address_information: 'Adresinformatie',
		birthday: 'Geboortedatum',
		city: 'Stad',
		country: {
			label: 'Land'
		},
		countrycode: {
			label: 'Landcode'
		},
		day: 'Dag',
		email: 'E-mailadres',
		emailconfirm: 'Herhaal e-mailadres *',
		extension: 'Toevoeging',
		filter: 'Kies een filter',
		filter_explain: 'Zoek wat je nodig hebt door een van de onderstaande categorieën te filteren.',
		firstname: 'Voornaam',
		gender: {
			female: 'Vrouw',
			label: 'Geslacht',
			male: 'Man',
			other: 'Ander'
		},
		lastname: 'Achternaam',
		month: 'Maand',
		personal_information: 'Persoonlijke informatie',
		phonenumber: 'Telefoonnummer',
		postalcode: 'Postcode',
		select: 'Een selectie maken',
		shipping_information: 'Verzendinformatie',
		state: 'Staat',
		select_arrival_date: 'Selecteer aankomstdatum',
		streetname: 'Straatnaam',
		streetnumber: 'Straatnummer',
		year: 'Jaar'
	},
	included: 'Inclusief',
	label: {
		account: 'Account',
		back: 'Terug',
		buy: 'Kopen',
		cancel: 'Annuleren',
		cart: {
			add: 'Toevoegen',
			added: 'Toegevoegd',
			add_to_cart: 'Toevoegen aan winkelwagen',
			add_to_wishlist: 'Toevoegen aan verlanglijst',
			add_to_selection: 'Toevoegen aan selectie',
			add_x_to_cart: 'Voeg {x} tickets toe aan winkelwagen',
			add_x_to_wishlist: 'Voeg {x} tickets toe aan je verlanglijstje',
			cart: 'Winkelwagen',
			complete_payment: 'Volledige betaling',
			complete_order: 'Voltooi bestelling',
			continue_shopping: 'Blijf winkelen',
			order: 'Klaar om te bestellen',
			remove: 'Verwijderen',
			remove_from_cart: 'Verwijderen uit winkelwagen',
			remove_from_wishlist: 'Verwijderen uit verlanglijstje',
			replace_item: 'Vervangen',
			pay: 'Betalen',
			save: 'Pre-registratie opslaan',
			sold_out: 'Dit product is uitverkocht',
			wishlist: 'Verlanglijst'
		},
		clear: 'Leegmaken',
		close: 'Sluiten',
		complete_your_experience: 'Maak je ervaring compleet',
		confirm: 'Bevestig',
		continue: 'Ga Verder',
		continue_shopping: 'Verder winkelen',
		filter: 'filter',
		finalize: 'Voltooi je bestelling',
		find_order: 'Vind mijn bestelling',
		extras: "extra's",
		in_stock: 'Op voorraad',
		including: 'Including',
		go_to_checkout: 'Ga naar het afrekenen',
		go_to_wishlist: 'Ga naar verlanglijst',
		log_out: 'Uitloggen ',
		merchandise: 'Merchandise',
		more_info: 'Meer info',
		navigation: {
			back: 'Ga terug',
			checkout: 'Afrekenen',
			go_to_checkout: 'Ga naar het afrekenen',
			next: 'Volgende',
			overview: 'Overzicht',
			previous: 'Vorig',
			wishlist: 'naar verlanglijstje'
		},
		newsletter_signup: 'Meld je aan voor de nieuwsbrief',
		no: 'Nee',
		no_items: 'Geen producten',
		order: 'Bestellen',
		order_number: 'Jouw ordernummer',
		reload_payment_methods: 'Klik dan hier',
		reset: 'reset',
		return_to_homepage: 'Keer terug naar de startpagina',
		sold_out: 'Uitverkocht',
		shop: {
			members_sale: 'Ticketshop',
			preregistration_sale: 'Verlanglijstje',
			regular_sale: 'Ticketshop',
			travel_sale: 'Campingticket upgradesp',
			wishlist: 'Verlanglijstje'
		},
		ticket: 'tickets',
		transportation: 'transport',
		travel: 'reis',
		try_again: 'Probeer het opnieuw',
		update: 'update',
		view_addons: "Bekijk extra's",
		yes: 'Ja'
	},
	loading: 'Laden ...',
	loading_one_moment: 'Eén moment alsjeblieft ...',
	modal: {
		save_time: {
			body: 'logNuInEnBespaarTijdTijdensHetAfrekenen',
			cta: 'Login van register',
			title: 'bespaarTijd'
		}
	},
	page: {
		checkout: {
			lead: 'Je hebt 5 minuten om jouw betaling te voltooien.',
			title: 'Selecteer jouw betaalmethode'
		},
		compare_transport: {
			lead: '',
			filter_title: 'Vind jouw vervoer',
			title: 'Georganiseerde busvervoer'
		},
		extras: {
			lead: 'Selecteer jouw aanvullende producten zoals parkeerkaarten, kluisjes en andere ervaringen.',
			title: "Extra's en andere ervaringen"
		},
		home: {
			lead: 'Welkom, kies een evenement om te beginnen.',
			title: '{Brand} One Stop Shop'
		},
		membership: {
			disclaimer_step:
				'The DEDIQATED Membership is a recurring membership. You will be charged for 1-year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			membership_includes: 'Membership includes',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
			disclaimer_checkout:
				'You are about to commit to a 1-year Membership. You agree that Q-dance will charge you for 1 year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			lead: '',
			title: 'MEMBERSHIP'
		},
		membership_monthly: {
			disclaimer_checkout:
				'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
			disclaimer_step:
				'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
			membership_includes: 'Membership omvat',
			lead: '',
			title: 'MEMBERSHIP'
		},
		merchandise: {
			filter_title: 'Merchandise',
			lead: 'Selecteer jouw merchandise.',
			one_size: 'Eén maat past alles',
			title: 'Merchandise'
		},
		payment_status: {
			canceled: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met jouw betaling en was jouw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat je aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling geannuleerd'
			},
			completed: {
				lead: '',
				message:
					'Bedankt voor jouw bestelling. We zijn momenteel jouw bestelling en betaling verwerken. Dit is geen bevestiging! Jouw bestelling wordt bevestigd nadat je een bevestigings-e-mail hebt ontvangen. Als je geen bevestigings-e-mail binnen 2 ontvangtUren, controleer jouw bankrekening of creditcard om te zien of de betaling succesvol was.',
				title: 'Bedankt'
			},
			error: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met jouw betaling en was jouw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat je aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling mislukt'
			},
			failed: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met jouw betaling en was jouw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat je aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling mislukt'
			},
			pending: {
				lead: '',
				message:
					"<p> Bedankt voor jouw bestelling. We wachten nog steeds op de definitieve bevestiging van jouw betaling door de betalingsprovider. </p> <p> Maak je geen zorgen, normaal gesproken krijgen we de bevestiging binnen een paar minuten. </p> <p> <u> Dit zal hierna gebeuren: </u> </p> <ul> <li> Jouw bestelling en betaling worden verwerkt </li> <li> Je ontvangt een bevestigingse-e-mail </li> <li> Je ontvangt een trackingcode zodra we jouw bestelling verzenden (indien van toepassing) </li> </ul> <br /> <br /> <p> Als het geld wordt afgetrokken van jouw bankrekening. En je krijgt geen bevestiging binnen 2 uur, neem contact op met <a target='_blank' href='https://www.justleep.it/'> Blaep klantenondersteuning </a>. </p> <p>Je kunt ook contact met hen opnemen voor andere vragen over jouw bestelling. </p> ",
				title: 'Betaling is in behandeling'
			},
			newsletter:
				'Meld je aan voor de nieuwsbrief en wees de eerste die op de hoogte is van belangrijke aankondigingen.',
			payment_provider: {
				adyen: {
					message:
						"Als je vragen hebt over jouw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://www.justleep.it/'> Bleep klantenservice </a>."
				},
				paylogic: {
					message:
						"Als je vragen hebt over jouw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://customerservice.paylogic.com/'> Paylogic klantenservice </a>."
				}
			}
		},
		shop: {
			experience_prebuilder: {
				form: ['Ik ben geïnteresseerd in', 'ik zal voorbij reizen', 'Hoeveel personen?'],
				lead: "Klik op 'Tickets Only' om tickets te bestellen. Gaat je voor de alles-in-één ervaring inclusief accommodaties? Klik op 'Travel & Stay-pakketten' voor alle reis- en accommodatiepakketten.",
				title: 'Creëer jouw ervaring',
				preregistration: {
					lead: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
					title: '{festival} verlanglijst'
				}
			},
			experience_builder: {
				choose: 'Kies jouw',
				select: 'Selecteer jouw',
				comparetransport: {
					label: 'Vervoer',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				extras: {
					label: "Extra's en andere ervaringen",
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				lead: 'Welke producten zou je aan jouw winkelwagen willen toevoegen?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				ticket: {
					label: '{Event} tickets',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				title: 'Je winkelwagen',
				transportation: {
					label: 'Georganiseerd bustransport',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				travel: {
					label: 'travel & Stay Packages',
					nights: 'nacht | nachten',
					persons: 'persoon | personen',
					remove: 'Verwijderen',
					update: 'Update boeken'
				}
			},
			lead: 'Alles wat je nodig hebt in één winkel.',
			title: 'Winkel'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'Je kunt nu producten toevoegen zoals kluisjes, parkeerkaarten, reizen en accommodatiepakketten en merchandise aan jouw winkelmandje.',
				title: "{Event} Extra's winkel"
			},
			experience_builder: {
				choose: 'Kies jouw',
				select: 'Selecteer jouw',
				comparetransport: {
					label: 'Vervoer',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				extras: {
					label: "Extra's en andere ervaringen",
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				lead: 'Welke producten zou je aan jouw winkelwagen willen toevoegen?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				ticket: {
					label: '{Event} tickets',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				title: 'Je winkelwagen',
				transportation: {
					label: 'Georganiseerd bustransport',
					nights: 'nacht | nachten',
					persons: 'persoon | personen',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				travel: {
					label: 'travel & Stay Packages',
					remove: 'Verwijderen',
					update: 'Update boeken'
				}
			},
			lead: 'Een overzicht van je vorige bestelling.',
			loading: {
				title: 'Jouw vorige bestelling controleren ...',
				lead: 'We halen je vorige bestelling op. Refresh deze pagina niet, het laden van je vorige bestelling kan tot een minuut duren. '
			},
			no_order: {
				lead: 'Vul een ordernummer (het ordernummer van de hoofdboeker) in dat je hebt ontvangen in de e-mail . Zorg ervoor dat je bent ingelogd met hetzelfde account dat is gebruikt om de bestelling te plaatsen. ',
				title: 'Er zijn geen eerdere bestellingen gevonden.'
			},
			package: {
				addons: 'Je gekozen uitbreidingen in het winkelmandje',
				summary: 'Samenvatting van je gekozen pakket'
			},
			title: 'Jouw gekochte producten'
		},
		tickets: {
			filter_explain: 'Filter toegangskaarten',
			includes: 'Ticket inclusief',
			includes_fee_tax: 'Inclusief servicekosten en BTW',
			includes_fee: 'Inclusief servicekosten',
			lead: 'Selecteer jouw toegangskaarten.',
			remove: 'Tickets verwijderen',
			select: 'Selecteer jouw tickets',
			sold_out: 'Uitverkocht',
			sold_out_combo:
				'{name} tickets zijn uitverkocht! Een beperkt bedrag kan nog steeds beschikbaar zijn in combinatie met ',
			sold_out_travel: 'Reispakketen',
			title: 'Tickets',
			upsell_accommodations:
				'Accommodatiepakketten zijn beschikbaar voor je om aan jouw bestelling toe te voegen.',
			upsell_extras: 'Extra producten zijn beschikbaar voor je om aan jouw bestelling toe te voegen.',
			upsell_travel: 'Reispakketten zijn beschikbaar voor je om aan jouw bestelling toe te voegen.'
		},
		transportation: {
			add: 'Toevoegen',
			arrival: 'Aankomst',
			choices: 'Vanuit waar begint de reis?',
			departure_time: '*Geschatte vertrektijden',
			disclaimer: '*Alle georganiseerde bussen gaan naar het',
			disclaimer2: 'festivalterrein.',
			eu: 'Europa',
			fee: '*Excl.€ 5,- servicekosten per bestelling ',
			filter_title: 'Vind jouw vervoer',
			from: 'Van',
			lead: 'Selecteer jouw georganiseerde bustransport.',
			netherlands: 'Nederland',
			remove: 'Verwijderen',
			select_other: 'Selecteer een andere optie.',
			sold_out: 'Uitverkocht',
			tickets: 'Buskaartjes',
			title: 'Georganiseerd bustransport',
			to: 'Naar'
		},
		travel: {
			accommodation_extras: "accommodatieExtra's",
			arrival_date_time: 'Aankomst dag en tijd',
			arrival_date: 'Aankomstdatum',
			available: 'Beschikbaar',
			category_extras: "Extra's",
			category_merchandise: 'Koopwaar',
			category_transfer: 'Transfers',
			create_package: 'Maak jouw pakket',
			departure_date: 'Vertrekdatum',
			filter_title: 'Vind jouw verblijf',
			includes: 'Pakket omvat',
			lead: 'Selecteer jouw reis- en verblijfpakketten.',
			make_selection: 'Maak je selectie',
			no_packages: 'Geen pakket (s) gevonden',
			no_ticket: 'Zonder ticket(s)',
			not_available: 'Niet beschikbaar',
			number_of_nights: 'Aantal nachten',
			package_price: 'Prijs voor reispakket',
			persons: 'Aantal personen',
			room_setup: 'Kies jouw kamerinstelling',
			select_other: 'Selecteer een andere optie.',
			select_tickets: 'Selecteer je tickets',
			selected: 'Geselecteerd',
			sold_out: 'Uitverkocht',
			still_available: 'Pakketten nog beschikbaar',
			tickets: 'tickets',
			title: 'travel &StayPackages',
			try_again: 'Probeer het opnieuw',
			warning: {
				title: 'Warning',
				message:
					'Je hebt al tickets in je winkelwagentje. Weet je zeker dat je extra tickets aan jouw reispakket wilt toevoegen? '
			}
		}
	},
	remaining: 'resterend',
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},
	time: {
		hour: 'uur | uren ',
		hour_abbr: 'H',
		minute: 'minute | minuten',
		minute_abbr: 'min',
		second: 'seconde | seconden ',
		second_abbr: 'S'
	},
	wish_list: {
		checkout: {
			add_all: 'Alle items toevoegen',
			add_wishlist_products: 'Voeg eerst producten toe',
			calculate: 'Bereken verzendkosten',
			cta: 'Naar verlanglijstje',
			cta_alternative: 'Naar winkelwagen',
			price: 'Prijs',
			save: {
				lead: 'Je verlanglijstje is opgeslagen. Tijdens de kaartverkoop kun je inloggen met hetzelfde account om je verlanglijstje in je winkelmand te laden. Wanneer je een nieuwe verlanglijstje maakt, wordt de oude overschreven.',
				title: 'Verlanglijst opslaan'
			},
			shipping_costs: {
				explainer: 'Het verzenden van (sommige) artikelen brengt verzendenkosten met zich mee.',
				label: 'Bereken verzendkosten'
			},
			title: 'Jouw verlanglijstje',
			total: 'Totaal'
		},
		experience_builder: {
			choose: 'Kies jouw',
			select: 'Selecteer jouw',
			comparetransport: {
				label: 'Vervoer',
				remove: 'Verwijderen',
				update: 'Wijzigen/toevoegen'
			},
			extras: {
				label: "Extra's en andere ervaringen",
				remove: 'Verwijderen',
				update: 'Wijzigen/toevoegen'
			},
			lead: 'Welke producten zou je aan jouw winkelwagen willen toevoegen?',
			merchandise: {
				label: 'Merchandise',
				remove: 'Verwijderen',
				update: 'Wijzigen/toevoegen'
			},
			ticket: {
				label: '{Event} tickets',
				remove: 'Verwijderen',
				update: 'Wijzigen/toevoegen'
			},
			title: 'Jouw verlanglijstje',
			transportation: {
				label: 'Georganiseerd bustransport',
				remove: 'Verwijderen',
				update: 'Wijzigen/toevoegen'
			},
			travel: {
				label: 'travel & Stay Packages',
				nights: 'nacht | nachten',
				persons: 'persoon | personen',
				remove: 'Verwijderen',
				update: 'Update boeken'
			}
		},
		lead: "Je kunt nu controleren of de producten van jouw verlanglijstje beschikbaar zijn. Zorg ervoor dat je ze aan je winkelmandje toevoegt als ze nog beschikbaar zijn. Product(en) uitverkocht? Je kunt een alternatief kiezen door op de knop 'vervangen' te klikken.",
		loading: {
			lead: 'We halen je opgeslagen verlanglijstje op. Refresh deze pagina niet, het laden van je verlanglijstje kan tot een minuut duren.',
			title: 'Je verlanglijstje wordt gecheckt'
		},
		preregistration: {
			title: 'Je verlanglijstje',
			lead: 'Maak een verlanglijstje en bespaar tijd tijdens de verkoop'
		},
		toast: 'Dit is jouw verlanglijstje. Voeg producten toe aan jouw verlanglijst om tijd te besparen tijdens de verkoop.',
		title: 'Je verlanglijstje'
	}
};
